<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="500">
            <v-card>
                <v-toolbar dark>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="hideModal">
                            <i class="fas fa-times"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <img :src="imagePath" style="width:100%" alt="">
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
    export default {
        methods: {
            showModal(path, name){
                this.path = path;
                this.name = name;
                this.dialog = true;
            },
            hideModal(){
                this.dialog = false;
            }
        },
        data(){
            return {
                name: '',
                path: '',
                dialog: false,
            }
        },
        computed:{
            imagePath() {
                return (
                    this.path+this.name+'.png'
                );
            },
        }
    }
</script>